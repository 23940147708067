/*----------  Header  ----------*/

header
{
	@include position(relative, 999);

	.header{
		&-text{
			p{
				.btn-fill, .btn-red{
					&:hover, &:focus, &:active{
						color: $white;
					}
				}
			}		
		}
	}
}