/*----------  Body  ----------*/

body
{
	@include font-p($font-base, $weight-regular, 1.2, $brand-dark);
}

.gm-style-iw {
	width: 500px !important; 
	overflow: inherit !important;
	min-height: 150px;
}

.navbar{
	.container{
		display: block;
		flex-direction: initial;
		justify-content: normal;
		align-items: normal;
	}

	&-nav{
		display: block;
		flex-direction: initial;
	}
}

.lightbox{
	&-popup{
		.owl-controls{
			.owl-prev, .owl-next{
				top: 0px;
			}
		}
	}
}

.btn-fill, .btn-red{
	&:hover, &:focus, &:active{
		color: $white;
		box-shadow: none;

		a{
			color: $white;
		}
	}
}

.select-menu{
	&:hover, &:focus, &:active{
		color: $white;
	}
}

@include media-breakpoint-down(md){

	.select-list{
		li{
			&:hover, &:focus, &:active{
				a{
					color: #333333;
				}
			}	
		}
	}
}

.grecaptcha-badge{
	opacity: 0;
	cursor: none;
}